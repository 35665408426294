import { apiService } from '@/services/api-services'
import { UserProfileStore } from '@/stores/userProfileStore'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class UserToFollowViewModel {
  @observable usersToFollow: UserProfileStore[] = []
  @observable userToFollowFetching = false
  @observable userToFollowTotalPage = 0
  @observable filter = { page: 0, pageSize: 20 }
  @observable loadMoreState = {page: 0, pageSize: 20}
  @observable loadingMore = false

  constructor() {
    this.fetchUsersToFollow(this.filter)
  }

  @asyncAction *fetchUsersToFollow(filter: { page: number; pageSize: number }) {
    try {
      this.userToFollowFetching = true
      const res = yield apiService.profiles.getUsersToFollow(filter)
      if (res) {
        this.usersToFollow = res.data.map(profile => new UserProfileStore(profile))
        this.userToFollowTotalPage = res.totalPage
      }
    } catch (error) {
    } finally {
      this.userToFollowFetching = false
    }
  }

  @asyncAction *loadMore() {
    try {
        this.loadingMore = true
        this.loadMoreState = {...this.loadMoreState, page: this.loadMoreState.page + 1}
        const res = yield apiService.profiles.getUsersToFollow(this.loadMoreState)
        const newUsersToFollow = res.data.map(profile => new UserProfileStore(profile))
        this.usersToFollow = [...this.usersToFollow, ...newUsersToFollow]
    } catch (error) {
    } finally {
        this.loadingMore = false
    }
  }

  @action.bound changeFilter(filter) {
    this.filter = {...this.filter, ...filter}
  }

  @computed get userToFollowCanLoadMore() {
    return this.filter.page < this.userToFollowTotalPage - 1
  }

  @computed get usersToFollowDisplayed() {
    return this.usersToFollow.filter(userProfileStore => userProfileStore.profile._id !== walletStore.userProfile?._id)
  }
}
